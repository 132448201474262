<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="mh-100vh bg-white content-page" v-else>
      <RegisterFormComponent
        :form="form"
        :v="$v"
        @checkForm="validateEmail"
        title="ตรวจสอบข้อมูล"
        :isDisplayTelephone="true"
        :isEdit="true"
        :disableBtn="disableBtn"
        :customField="customField"
        :dynamicField="dynamicField"
        :questionList="questionList"
        :chkBirthDay="chkBirthDay"
        :branchList="branchList"
        :is_privacy_policy1="is_privacy_policy1"
        :fieldInForm="fieldInForm2"
      />
    </div>
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import {
  required,
  helpers,
  email,
  minLength,
  maxLength,
  numeric,
  requiredIf
} from "vuelidate/lib/validators";
import { fieldInForm2, profile_fields2 } from "@/assets/json/fieldProfile.json";
import ModalError from "@/components/alert-modal/ModalError";
import moment from "moment";
import RegisterFormComponent from "@/components/register/RegisterFormComponent";
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
import OtherLoading from "@/components/other/OtherLoading";
var profile_field = { ...profile_fields2 };
export default {
  components: {
    ModalError,
    RegisterFormComponent,
    OtherLoading
  },
  validations() {
    for (const item of this.customField) {
      const key = this.checkIsProfile(item.field_profile_type_id);

      if (key) {
        if (item.required) {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { required, email };
          } else if (item.field_profile_type_id == 10)
            profile_fields2[key] = {
              required,
              maxLength: maxLength(10),
              minLength: minLength(10),
              numeric
            };
          else if (item.field_profile_type_id == 9) {
            profile_fields2["birthday_day"] = {
              required
            };
            profile_fields2["birthday_month"] = {
              required
            };
            profile_fields2["birthday_year"] = {
              required
            };
          } else if (item.field_profile_type_id == 1)
            profile_fields2[key] = {
              required,
              Script_checkID,
              maxLength: maxLength(13),
              minLength: minLength(13),
              numeric
            };
          else profile_fields2[key] = { required };
        } else {
          profile_fields2[key] = {};
        }
      }
    }
    return {
      form: profile_fields2,
      dynamicField: {
        $each: {
          user_answer: {
            required: requiredIf(el => el.required)
          }
        }
      }
    };
  },
  data() {
    return {
      chkBirthDay: process.env.VUE_APP_BIRTHDAY,
      fieldInForm2: [...fieldInForm2],
      form: {
        registerTypeId: 2,
        SocialProvider: "line",
        SocialId: "",
        password: "",
        display_name: "",
        branch_id: "0",
        t_datetime: "",
        line_id: "",
        the_one_card_member: "",
        line_ref: "",
        accept_terms_and_conditions: 1,
        is_consent: 0,
        is_privacy_policy: 0,
        ...profile_field
      },
      pairform: {
        registerTypeId: 2,
        socialProvider: "line",
        socialId: "",
        telephone: ""
      },
      acceptTerm: false,
      isLoading: true,
      required: false,
      validateBirthDate: false,
      errorBirthDate: "",
      successMessage: "",
      emailFirst: "",
      disableBtn: false,
      customField: [],
      questionList: [],
      checkBD18: true,
      dynamicField: [],
      is_privacy_policy1: 0,
      consentShow: false,
      branchList: []
    };
  },

  async created() {
    if (!this.$cookies.get("hourglass_register_success")) {
      await this.getUserProfile();
    } else {
      if (this.$route.query.redirect)
        return this.$router.push(this.$route.query.redirect);
      else {
        this.$cookies.set("popupModalRegister", true);
        this.$router.push("/profile");
      }
    }
  },
  methods: {
    checkIsProfile(val) {
      const findField = fieldInForm2.find(el => el.id == val);
      return findField ? findField.key : false;
    },
    async getUserProfile() {
      await this.$store.dispatch("getUserApi");

      this.form = this.$store.state.shortProfile;
      this.consentShow = this.form.is_consent == 1 && true;

      this.emailFirst = this.form.email;
      await this.getCustomField();
    },
    async getCustomField() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/user/registerform/1`)
        .then(async data => {
          this.isLoading = false;
          if (data.result === 1) {
            this.customField = data.detail.result.fields;
            for (const field of data.detail.result.custom_field) {
              // console.log(this.form.custom_field);
              let form = this.form.custom_field.find(el => el.id == field.id);
              console.log(form);
              if (form) {
                let value = form.user_answer.map(el => el.answer);
                if (field.field_type_id == 2) {
                  field.user_answer = value;
                } else {
                  field.user_answer = value.toString();
                }
              }

              if (field.field_type_id == 6) {
                this.getBranch(field.branch_group_id);
              }
            }
            this.dynamicField = data.detail.result.custom_field.filter(
              el => el.is_show == 1
            );
          }
        });
    },
    async validateEmail(val) {
      this.form = val.form;
      this.checkBD18 = val.chk18Birthday;

      this.pairform.telephone = this.form.telephone;
      this.pairform.socialId = this.$cookies.get("user_profile_token").userId;

      this.$v.form.$touch();
      this.$v.dynamicField.$touch();
      if (this.$v.form.$error || this.$v.dynamicField.$error) {
        return;
      }
      if (!this.checkBD18) {
        return;
      }
      delete this.form.birthday;
      this.isLoading = true;
      if (this.form.email != this.emailFirst) {
        let chkEmail = {
          Email: this.form.email
        };
        this.disableBtn = true;
        this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/user/ValidateEmail`,
            chkEmail
          )
          .then(data => {
            if (data.result == 1) {
              this.checkForm();
            } else {
              this.successMessage = "Email นี้เคยสมัครแล้ว";
              this.$refs.modalError.show();
              this.isLoading = false;
              this.disableBtn = false;
            }
          });
      } else {
        this.checkForm();
      }
    },
    checkForm: async function () {
      // console.log("object");
      this.isLoading = true;
      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/pair`, this.pairform)
        .then(data => {
          if (data.result == 1) {
            this.$cookies.remove("modalShowId");
            this.$cookies.set("hourglass_token", data.detail.token);
            this.editProfile();
          } else {
            this.successMessage = data.message;
            this.$refs.modalError.show();
            this.isLoading = false;
            this.disableBtn = false;
          }
        });
    },

    async editProfile() {
      if (this.form.is_consent) {
        this.form.is_consent = 1;
      } else {
        this.form.is_consent = 0;
      }
      if (!this.form.birthday_day) this.form.birthday_day = "01";
      if (!this.form.birthday_month) this.form.birthday_month = "01";
      if (!this.form.birthday_year) this.form.birthday_year = "1753";
      let dynamic = [];
      for (const fields of this.dynamicField) {
        let target_id = 0;
        if (fields.field_choices.length > 0) {
          const findFieldId = fields.field_choices.find(
            choice => choice.name == fields.user_answer
          );
          if (findFieldId) {
            target_id = findFieldId.id;
          }
        }
        dynamic.push({
          id: fields.id,
          value: fields.user_answer,
          target_id: target_id
        });
      }
      let objTemp = [];

      for (const x of dynamic) {
        var findField = this.dynamicField.find(field => field.id == x.id);

        if (x.value && typeof x.value == "object") {
          if (x.value.length > 0) {
            for (const a of x.value) {
              const { id } = findField.field_choices.find(
                choice => choice.name == a
              );
              objTemp.push({
                id: x.id,
                value: a,
                target_id: id
              });
            }
          }
        } else {
          objTemp.push(x);
        }
      }

      let payload = {
        ...this.form,
        custom_field: objTemp.filter(el => el.value)
      };
      if (payload.birthday_day == "") payload.birthday_day = "01";
      if (payload.birthday_month == "") payload.birthday_month = "01";
      if (payload.birthday_year == "") payload.birthday_year = "1753";
      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/EditUserProfile`, payload)
        .then(data => {
          if (data.result == 1) {
            if (this.customField.length > 0) {
              this.updateCustomField();
            } else {
              if (this.$route.query.redirect) {
                if (
                  this.$route.query.redirect.indexOf("http://") == 0 ||
                  this.$route.query.redirect.indexOf("https://") == 0
                ) {
                  return (window.location.href = this.$route.query.redirect);
                } else {
                  return this.$router.push({
                    path: this.$route.query.redirect,
                    query: { ...this.$route.query }
                  });
                }
              } else {
                this.$cookies.set("popupModalRegister", true);
                this.$router.push("/profile");
              }
              this.isLoading = false;
            }
          } else {
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
            this.isLoading = false;
          }
        })
        .catch(er => {
          this.successMessage = er.response.data.title;

          this.$refs.modalError.show();
          this.isLoading = false;
        });
    },
    async updateCustomField() {
      let body = {
        user_guid: this.form.user_guid,
        admin_user_guid: this.form.user_guid,
        question_list: this.questionList
      };
      await this.$axios
        .put(
          `${process.env.VUE_APP_API}/api/v1/User/update_custom_answer`,
          body
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            if (this.$route.query.redirect) {
              if (
                this.$route.query.redirect.indexOf("http://") == 0 ||
                this.$route.query.redirect.indexOf("https://") == 0
              ) {
                return (window.location.href = this.$route.query.redirect);
              } else {
                return this.$router.push({
                  path: this.$route.query.redirect,
                  query: { ...this.$route.query }
                });
              }
            } else {
              this.$cookies.set("popupModalRegister", true);
              this.$router.push("/profile");
            }
          } else {
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    dateFormat() {
      let today = new Date();
      return moment(today).format();
    },
    async getBranch(branch_id) {
      this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/Reward/redeem_coupon/${branch_id}`
        )
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              var branchList = [];
              for (const { branch } of data.detail) {
                const filter = branch.filter(el => el.is_check);
                branchList.push(...filter);
              }
              this.branchList = branchList;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped>
.label-remark {
  color: red;
}
.ft-20 {
  font-size: var(--text-xl);
}
.div-space {
  margin: 90px 0px;
}
.text-error {
  color: #ff0000;
  font-size: var(--text-md);
}
.vdatetime-input.error {
  border-color: red !important;
}
.cl-gray {
  color: #575757;
}
</style>
